* {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  max-width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* overflow-x: hidden; */
}

h1 {
  color: black;
}

p {
  color: black;
}

.logo-img {
  width: auto;
  height: auto;
  max-height: 60px;
}
